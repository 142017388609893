import { Controller } from 'stimulus'
import {
    bootboxConfirm,
    resetSearchKey,
    showToastr,
    errorsList,
    renderModal,
    showModal,
    enableOrDisable,
    parseDate
} from '../../helpers/utils'
import { getJsonRequest } from '../../helpers/request'
import Rails from 'rails-ujs'

const defaultGijgoConf = {
    format: global.gijgoDefaultFormat,
    weekStartDay: 1,
    uiLibrary: 'bootstrap4',
    locale: $('body').data('locale')
}

export default class extends Controller {
    static targets = ["ofiShow", "programForm"]

    initialize() {}

    connect() {
        if (this.hasOfiShowTarget) {
            this.initializeDatepickers();
        }
        if (this.hasProgramFormTarget) {
            this.initializeFormChangeEvent();
        }
    }

    /* BULK ACTION */
    quickEdit(event) {
        let currentProgramId = event.target.dataset.id;
        let versionId = event.target.dataset.versionid;
        this.renderModal(programsBasePath + '/' + currentProgramId + '/quick_edit?version_id=' + versionId)
    }

    renderModal(url) {
        let that = this
        getJsonRequest(url).then(response => {
          if (response.success) {
            showModal(response.html, 'atalisModal', 'atalisModal')
            that.initializeDatepickers()
          }
        })
    }

    updateSuccess(event) {
        let [data, status, xhr] = event.detail;
        if (data.success) {
            window.location.reload()
        } else {
            this.batchActionError(event)
        }
    }

    updateError(event) {
        let [data, status, xhr] = event.detail;
        let el = event.target.querySelector('.error-list-container')
        if (!data.success) {
            if (el) {
                el.innerHTML = errorsList(data.errors)
            } else {
                showToastr(errorTitle, errorsList(data.errors), 'error')
            }
        }
    }

    collapseCalendar() {
        $("#calender-panel").addClass('hidden')
        $("#collapse-calendar-icon").addClass('hidden')
        $("#expand-calendar-icon").removeClass('hidden')
    }

    expandCalendar() {
        $("#calender-panel").removeClass('hidden')
        $("#expand-calendar-icon").addClass('hidden')
        $("#collapse-calendar-icon").removeClass('hidden')
    }

    initializeDatepickers() {
        let that = this;
        $(".eoi_deadline-datepicker").each(function(index, el) {
            $(el).datepicker({
                format: global.gijgoDefaultFormat,
                weekStartDay: 1,
                uiLibrary: 'bootstrap4',
                locale: $('body').data('locale'),
                change: function(e) {
                    $(e.target.parentNode.parentNode).children('.eoi_deadline_enabled')[0].value = (e.currentTarget.value)
                },
                open: function(e) {
                    let dataGuid = e.target.getAttribute('data-guid');
                    window.setInterval(function() {
                        that.markOfficialDeadline(e.target, dataGuid);
                    }, 100);
                }
            });
        });
        $(".internal_deadline-datepicker").each(function(index, el) {
            $(el).datepicker({
                format: global.gijgoDefaultFormat,
                weekStartDay: 1,
                uiLibrary: 'bootstrap4',
                locale: $('body').data('locale'),
                change: function(e) {
                    $(e.target.parentNode.parentNode).children('.internal_deadline_enabled')[0].value = (e.currentTarget.value)
                },
                open: function(e) {
                    let dataGuid = e.target.getAttribute('data-guid');
                    window.setInterval(function() {
                        that.markOfficialDeadline(e.target, dataGuid);
                    }, 100);
                }
            });
        });
    }

    markOfficialDeadline(target, dataGuid) {
        let officialDeadline = target.getAttribute('data-official-deadline');
        var currentDate = parseDate(officialDeadline, global.momentDefaultFormat);

        var day = currentDate._d.getDate();
        var month = currentDate._d.getMonth();
        var year = currentDate._d.getFullYear();
        if (document.querySelector('[guid="' + dataGuid + '"]').querySelector('[year="' + year + '"][month="' + month + '"][day="' + day + '"]')) {
            document.querySelector('[guid="' + dataGuid + '"]')
                    .querySelector('[year="' + year + '"][month="' + month + '"][day="' + day + '"]')
                    .classList
                    .add('deadline-date')
        }
    }

    updateCustomerPublished(event) {
        let programVersionId = event.target.dataset.programVersionId;
        let programCustomerId = event.target.dataset.programCustomerId;
        let programId = event.target.dataset.programId;
        let confirmationText = '';
        if(event.target.checked) {
            confirmationText = publishConfirmationText;
        } else {
            confirmationText = unpublishConfirmationText
        }
        bootboxConfirm(confirmationText, yesText, noText, function(result) {
            var data = {
                program_version_id: programVersionId,
                program_customer_id: programCustomerId,
                customer_published: event.target.checked
            }
            if (result) {
                fetch(programsBasePath + '/' + programId + '/publish', {
                    method: 'PATCH',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': Rails.csrfToken()
                    },
                    credentials: 'same-origin'
                })
                .then((response) => response.json())
                .then(function(data) {
                    if (data.success) {
                        showToastr(successTitle, data.message, 'success')
                    } else {
                        showToastr(errorTitle, errorsList(data.errors), 'error')
                        event.target.checked = !event.target.checked;
                    }
                })
                .catch((error) => {
                    event.target.checked = !event.target.checked;
                    showToastr(errorTitle, errorsList([someThingWentWrong]), 'error')
                });
            }
        })
    }

    /* Filters */
    radioButtonFilter(event) {
        let that = this
        if(global.showProgramSaveWarning) {
          bootboxConfirm(programSaveConfirmationText, 'Continue', 'Cancel', function(result) {
              if (result) {
                that.triggerRadioButtonFilter(event)
              } else {
                return
              }
          })
        } else {
          this.triggerRadioButtonFilter(event)
        }
    }

    unpageRadioButtonFilter(event) {
        const field = event.target.dataset.field;
        const value = event.target.dataset.value;
        const baseUrl = event.target.dataset.searchurl;

        const url = new URL(baseUrl, window.location.origin);

        const params = new URLSearchParams(window.location.search);
        params.delete('per_page');
        params.delete('page');

        params.set(field, value);

        url.search = params.toString();

        window.location.href = url.toString();
    }


    triggerRadioButtonFilter(event) {
        let field = event.target.getAttribute('data-field')
        let value = event.target.getAttribute('data-value')

        let url = new URL(window.location.href);
        let fieldInUrl = url.searchParams.get(field);
        resetSearchKey(url);
        if (fieldInUrl === value) {
            url.searchParams.delete(field);
        } else {
            url.searchParams.set(field, value);
        }
        window.location = event.target.dataset.searchurl + url.search
    }

    inputFieldFilter(event) {
        let that = this
        if(global.showProgramSaveWarning) {
          bootboxConfirm(programSaveConfirmationText, 'Continue', 'Cancel', function(result) {
              if (result) {
                that.triggerInputFieldFilter(event)
              } else {
                return
              }
          })
        } else {
          this.triggerInputFieldFilter(event)
        }
    }

    triggerInputFieldFilter(event) {
        let field = event.target.getAttribute("data-field");
        let value = event.target.value;
        let url = new URL(window.location.href);
        let fieldInUrl = url.searchParams.get(field);
        if (value) {
            resetSearchKey(url);
            url.searchParams.set(field, value);
            window.location = event.target.dataset.searchurl + url.search
        } else if (fieldInUrl) {
            resetSearchKey(url);
            url.searchParams.delete(field);
            window.location = event.target.dataset.searchurl + url.search
        }
    }

    toggleCalls(event) {
        let field = event.target.getAttribute("data-field");
        let labelEl = event.target.parentElement;
        let openEl = document.querySelector(".open-calls");
        let closedEl = document.querySelector(".closed-calls");
        $(".program-calls label.radio-wrapper").removeClass("selected");
        $(labelEl).addClass("selected");
        if (field === 'open') {
            $(openEl).removeClass("hide");
            $(closedEl).addClass("hide");
        } else if (field === 'closed') {
            $(openEl).addClass("hide");
            $(closedEl).removeClass("hide");
        }
    }

    toggleEoiDeadline(event) {
        let $parentFragment = $(event.target).parents('.call-fragment')
        let $currentDate = $parentFragment.find('#eoi_deadline').val()
        let $enabledDatepicker = $parentFragment.find('.eoi_deadline_enabled')[0]
        let $disabledDatepicker = $parentFragment.find('.eoi_deadline_disabled')[0]

        if (!event.target.checked) {
            $enabledDatepicker.value = ''
            $disabledDatepicker.value = $currentDate
            enableOrDisable($disabledDatepicker.parentNode, false)
        } else {
            $enabledDatepicker.value = $currentDate
            $disabledDatepicker.value = ''
            enableOrDisable($enabledDatepicker.parentNode, true)
        }
    }

    initializeFormChangeEvent() {
        $("#program-ofi-show-form :input").change(function() {
           showProgramSaveWarning = true
        });
    }

    toggleInternalDeadline(event) {
        let $parentFragment = $(event.target).parents('.call-fragment')
        let $currentDate = $parentFragment.find('#internal_deadline').val()
        let $enabledDatepicker = $parentFragment.find('.internal_deadline_enabled')[0]
        let $disabledDatepicker = $parentFragment.find('.internal_deadline_disabled')[0]

        if (!event.target.checked) {
            $enabledDatepicker.value = ''
            $disabledDatepicker.value = $currentDate
            enableOrDisable($disabledDatepicker.parentNode, false)
        } else {
            $enabledDatepicker.value = $currentDate
            $disabledDatepicker.value = ''
            enableOrDisable($enabledDatepicker.parentNode, true)
        }
    }

    deleteDocumentSuccess(event) {
        let [data, status, xhr] = event.detail;

        if (data.success) {
            event.target.parentElement.remove();
            event.stopPropagation();
        }
    }
}
