import { Controller } from "stimulus"
import {
    showModal,
    selectAllCheckboxes,
    errorsList,
    showToastr,
    bootboxConfirm,
    initializeSelect2,
    enableOrDisable,
    addToDate,
    parseDate
} from "../helpers/utils"
import { getJsonRequest } from "../helpers/request"
import Rails from 'rails-ujs'
import moment from 'moment'

const defaultGijgoConf = {
    format: global.gijgoDefaultFormat,
    weekStartDay: 1,
    uiLibrary: 'bootstrap4',
    locale: $('body').data('locale')
}

export default class extends Controller {
    static targets = [
        "selectAll", "programCallIndexCheckbox", "editSelected",
        "noMultipleEditTemplate", "newProgramCallBtn", "newProgramCallFormTemplate", "selectedIds",
        "activateSelected", "deactivateSelected", "duplicateSelected", "deleteSelected", "phase2DeadlineInput",
        "programCallForm"
    ]

    initialize() {}

    connect() {
        // this.initializeCocoonCallbacks()
        this.initializeSelect2Dropdowns()
        // this.setDefaultInternalAndEoiDeadline()
    }

    selectAllRecords(event) {
        let target = event.target;
        selectAllCheckboxes(this.programCallIndexCheckbox, target.checked)
        this.enableOrDisableProgramCalls()
        this.showOrHideTopMenu()
    }

    selectRecord(event) {
        this.enableOrDisableProgramCalls()
        this.showOrHideTopMenu()
    }

    showOrHideTopMenu() {
        if (this.programCallIndexCheckboxTargets.some(chkbox => chkbox.checked)) {
            $(event.target).parents('.table-wrapper').find('.top-menu').removeClass('hidden')
        } else {
            $(event.target).parents('.table-wrapper').find('.top-menu').addClass('hidden')
        }
    }

    initializeSelect2Dropdowns() {
        initializeSelect2(".calls-university-dropdown", { select: this.onUniversitySelect }, { placeholder: select2PlaceHolder })
    }

    initializeCocoonCallbacks() {
        let that = this
        $('#customer_calls_wrapper').on('cocoon:after-insert', function(e, added_customer_call) {
            var numTabs = $("#program-call-form div#customer_calls_wrapper ul li").length + 1;

            $("#program-call-form div#customer_calls_wrapper ul").append(
                "<li><a id='fragment-link-" + numTabs + "' href='#fragment-" + numTabs + "'><span>Customer-" + numTabs + "</span></a></li>"
            )
            $(added_customer_call).attr('id', 'fragment-' + numTabs)
            $("#program-call-form div#customer_calls_wrapper").tabs("refresh")
            $('#fragment-link-' + numTabs).click()
            $("#fragment-" + numTabs + " .eoi_deadline-datepicker").datepicker(defaultGijgoConf);
            $("#fragment-" + numTabs + " .internal_deadline-datepicker").datepicker(defaultGijgoConf);
            $("#fragment-" + numTabs + " .call-title").text($("#program_call_name").val())
            enableOrDisable($("#fragment-" + numTabs + " .eoi_deadline-datepicker")[0].parentNode, false)
            enableOrDisable($("#fragment-" + numTabs + " .internal_deadline-datepicker")[0].parentNode, false)
            initializeSelect2(
                "#fragment-" + numTabs + " .calls-university-dropdown", {
                    select: function(e) {
                        let data = e.params.data;
                        $(e.delegateTarget).parents('.customer-call-fragment').find('.eoi-email-call-input').val(universitiesConfig[parseInt(data.id)].eoi_email)
                        $('#fragment-link-' + numTabs).html('<span>' + data.text + '</span>')
                    }
                }, { placeholder: select2PlaceHolder }
            )
        })

        $('#customer_calls_wrapper').on('cocoon:before-remove', function(e, removedItem, originalEvent) {
            let id = removedItem[0].id
            let $el = $("a[href='#" + id + "']")
            let $parent = $el.parent()
            $parent.remove()
            $el.remove()
        });
    }

    enableOrDisableProgramCalls() {
        if (this.programCallIndexCheckboxTargets.some(chkbox => chkbox.checked)) {
            this.deleteSelectedTarget.classList.remove('disabled-program-call')
            this.editSelectedTarget.classList.remove('disabled-program-call')
        } else {
            this.deleteSelectedTarget.classList.add('disabled-program-call')
            this.editSelectedTarget.classList.add('disabled-program-call')
        }
    }

    /* BULK ACTION */

    fetchSelectedIds() {
        // let that = this
        let selectedIds = []
        let selectedCount = 0
        this.programCallIndexCheckboxTargets.forEach((el, i) => {
            if (el.checked) {
                selectedIds.push(el.getAttribute('value'))
                selectedCount = selectedCount + 1
            }
        })
        if (selectedCount >= 1) {
            this.selectedIdsTarget.value = JSON.stringify(selectedIds)
        }
        // } else {
        //   toastr.error("Please select at least one record")
        // }
        return selectedIds
    }

    copyNameToCustomerBlock(e) {
        $(".call-title").text(e.target.value)
    }

    isBootboxRunning = false;
    eoiDeadlinePresent = false;
    internalDeadlinePresent = false;

    deadlineChange() {
        if ((this.eoiDeadlinePresent || this.internalDeadlinePresent) && this.isEditProgramCall) {
            if (this.isBootboxRunning) {
                return;
            }

            this.isBootboxRunning = true;

            let that = this;
            bootboxConfirm(changeDeadlineWarning, yesText, noText, function(result) {
                if (result) {
                    that.internalDeadlinesChange();
                }

                that.isBootboxRunning = false;
            }.bind(this));

        } else {
            this.internalDeadlinesChange();
        }
    }

    internalDeadlinesChange() {
        $('.eoi_deadline-datepicker').each(function(index, el) {
            this.setDefaultEoiDeadline(el);
        }.bind(this));

        $('.internal_deadline-datepicker').each(function(index, el) {
            this.setDefaultInternalDeadline(el);
        }.bind(this));
    }

    deleteDocumentSuccess(event) {
        let [data, status, xhr] = event.detail;

        if (data.success) {
            event.target.parentElement.remove();
            event.stopPropagation();
        }
    }

    setDefaultInternalAndEoiDeadline() {
        let that = this
        if (this.hasProgramCallFormTarget) {
            $('.eoi_deadline-datepicker').each(function(index, el) {
                if ($(el).val() == 0) {
                    that.setDefaultEoiDeadline(el)
                }
            });

            $('.internal_deadline-datepicker').each(function(index, el) {
                if ($(el).val() == 0) {
                    that.setDefaultInternalDeadline(el)
                }
            });
        }
    }

    setDefaultEoiDeadline(el) {
        let deadline = $("#deadline-datepicker").val()

        let universityId, universityConfig;
        let eoiDeadline, eoiDeadlineDefault, eoiAdder;
        universityId = $(el).data('university-id')
        universityConfig = universitiesConfig[parseInt(universityId)]
        eoiDeadlineDefault = universityConfig.eoi_deadline_default
        eoiAdder = universityConfig.eoi_deadline_adder_type

        let isDatepickerActive = $('.eoi-deadline-toggle-' + universityId).is(':checked')

        if (deadline.length > 0 && isDatepickerActive && eoiDeadlineDefault && eoiAdder) {
            eoiDeadline = addToDate(deadline, global.momentDefaultFormat, eoiDeadlineDefault, eoiAdder)
            $(el).val(eoiDeadline)
            $(el).parent().parent().children(".eoi_deadline_enabled").val(eoiDeadline)
        }
    }

    setDefaultInternalDeadline(el) {
        let deadline = $("#deadline-datepicker").val();
        let universityId, universityConfig;
        let internalDeadline, internalDeadlineDefault, internalAdder;

        universityId = $(el).data('university-id');
        universityConfig = universitiesConfig[parseInt(universityId)];
        internalDeadlineDefault = universityConfig.internal_deadline_default;
        internalAdder = universityConfig.internal_deadline_adder_type;

        let isDatepickerActive = $('.internal-deadline-toggle-' + universityId).is(':checked');

        if (deadline.length > 0 && isDatepickerActive && internalDeadlineDefault && internalAdder) {
            internalDeadline = addToDate(deadline, global.momentDefaultFormat, internalDeadlineDefault, internalAdder);

            // Special handling for SP
            if (parseInt(universityId) === 8) {
                let deadlineDate = moment(internalDeadline, global.momentDefaultFormat);
                let dayOfWeek = deadlineDate.day();

                if (dayOfWeek === 6) { // Saturday
                    deadlineDate.subtract(1, 'days'); // Move to Friday
                } else if (dayOfWeek === 0) { // Sunday
                    deadlineDate.add(1, 'days'); // Move to Monday
                }

                internalDeadline = deadlineDate.format(global.momentDefaultFormat);
            }

            $(el).val(internalDeadline);
            $(el).parent().parent().children(".internal_deadline_enabled").val(internalDeadline);
        }
    }

    showBootboxConfirm(confirmationText, url) {
        let that = this
        let selectedIds = that.fetchSelectedIds()
        if (selectedIds && selectedIds.length > 0) {
            bootboxConfirm(confirmationText, yesText, noText, function(result) {
                var data = { program_call_ids: JSON.stringify([selectedIds]) }
                if (result) {
                    fetch(url, {
                        method: 'POST',
                        body: JSON.stringify(data),
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRF-Token': Rails.csrfToken()
                        },
                        credentials: 'same-origin'
                    }).then(function(response) {
                        window.location.reload()
                    }).then(function(data) {
                        showToastr(errorTitle, errorsList(data.errors), 'error')
                    });
                }
            })
        }
    }

    editProgramCalls() {
        if (event.target.dataset.actionName == 'call_title_click') {
            $(event.target).parents('table').find('input.program_calls-index-checkbox').attr('checked', false)
            $(event.target).parents('tr').find('input.program_calls-index-checkbox').attr('checked', 'checked')
        }
        this.isEditProgramCall = true;
        let url = this.activateSelectedTarget.dataset.url
        let selectedIds = this.fetchSelectedIds()
        let selectedCount = selectedIds.length
        if (selectedCount == 0) { return }
        if (selectedCount == 1) {
            let editCallUrl = programCallsBasePath + '/' + selectedIds[0] + '/edit?program_version_id=' + versionId
            this.showProgramCallForm(this, editCallUrl)
        } else {
            toastr.error(noMultipleEditText)
        }
    }

    activateProgramCalls() {
        let url = this.activateSelectedTarget.dataset.url
        this.showBootboxConfirm(activateCallConfirmationText, url)
    }

    deactivateProgramCalls() {
        let url = this.deactivateSelectedTarget.dataset.url
        this.showBootboxConfirm(deactivateCallConfirmationText, url)
    }

    duplicateProgramCalls() {
        let url = this.duplicateSelectedTarget.dataset.url
        this.showBootboxConfirm(duplicateCallConfirmationText, url)
    }

    deleteProgramCalls() {
        let url = this.deleteSelectedTarget.dataset.url
        this.showBootboxConfirm(programCallDeleteConfirmationText, url)
    }

    batchActionSuccess(event) {
        let [data, status, xhr] = event.detail;
        if (data.success) {
            window.location.reload()
        } else {
            this.batchActionError(event)
        }
    }

    batchActionError(event) {
        let [data, status, xhr] = event.detail;
        let el = event.target.querySelector('.error-list-container')
        if (!data.success) {
            if (el) {
                el.innerHTML = errorsList(data.errors)
            } else {
                showToastr(errorTitle, errorsList(data.errors), 'error')
            }
        }
    }

    onUniversitySelect(e) {
        let data = e.params.data;
        console.log(data)
        let $parent = $(e.delegateTarget).parents('.customer-call-fragment')
        $parent.find('.eoi-email-call-input').val(universitiesConfig[parseInt(data.id)].eoi_email)
        $("a[href='#" + $parent.attr('id') + "']").html('<span>' + data.text + '</span>')
    }

    togglePhase2Deadline(event) {
        if (!event.target.checked) {
            this.phase2DeadlineInputTarget.value = ''
            enableOrDisable(this.phase2DeadlineInputTarget.parentNode, false)
        } else {
            enableOrDisable(this.phase2DeadlineInputTarget.parentNode, true)
        }
    }

    toggleEoiDeadline(event) {
        let $parentFragment = $(event.target).parents('.customer-call-fragment')
        let $currentDate = $parentFragment.find('#eoi_deadline').val()
        let $enabledDatepicker = $parentFragment.find('.eoi_deadline_enabled')[0]
        let $disabledDatepicker = $parentFragment.find('.eoi_deadline_disabled')[0]

        if (!event.target.checked) {
            $enabledDatepicker.value = ''
            $disabledDatepicker.value = $currentDate
            $disabledDatepicker.parentNode
              .querySelector("div.gj-datepicker")
              .classList.add("not-clickable");
        } else {
            $enabledDatepicker.value = $currentDate
            $disabledDatepicker.value = ''
            $enabledDatepicker.parentNode
              .querySelector("div.gj-datepicker")
              .classList.remove("not-clickable");
        }
    }

    toggleInternalDeadline(event) {
        let $parentFragment = $(event.target).parents('.customer-call-fragment')
        let $currentDate = $parentFragment.find('#internal_deadline').val()
        let $enabledDatepicker = $parentFragment.find('.internal_deadline_enabled')[0]
        let $disabledDatepicker = $parentFragment.find('.internal_deadline_disabled')[0]

        if (!event.target.checked) {
            $enabledDatepicker.value = ''
            $disabledDatepicker.value = $currentDate
            $disabledDatepicker.parentNode
                .querySelector("div.gj-datepicker")
                .classList.add("not-clickable");
        } else {
            $enabledDatepicker.value = $currentDate
            $disabledDatepicker.value = ''
            $enabledDatepicker.parentNode
                .querySelector("div.gj-datepicker")
                .classList.remove("not-clickable");
        }
    }

    toggleCustomerCallPanel(event) {
        let $parentFragment = $(event.target).parents('.customer-call-fragment')
        let $internal_datepicker = $parentFragment.find('.internal_deadline-datepicker')[0]
        let $eoi_deadline_datepicker = $parentFragment.find('.eoi_deadline-datepicker')[0]

        checked = event.target.checked
        this.enableOrDisableDatePicker($internal_datepicker, false)
        this.enableOrDisableDatePicker($eoi_deadline_datepicker, false)
    }

    enableOrDisableDatePicker(datepicker, enable) {
        if (enable) {
            enableOrDisable(datepicker.parentNode, true)
        } else {
            datepicker.value = ''
            enableOrDisable(datepicker.parentNode, false)
        }
    }

    /* BULK ACTION */

    newProgramCall() {
        let that = this;
        let programId = that.newProgramCallBtnTarget.dataset.programId
        if (!programId) {
            showToastr(errorTitle, saveProgramFirstMessage, 'error')
            return;
        }
        this.isEditProgramCall = false;
        let programVersionId = that.newProgramCallBtnTarget.dataset.programVersionId
        let url = programCallsBasePath + '/new?program_id=' + programId + '&program_version_id=' + programVersionId

        this.showProgramCallForm(that, url)
    }

    showProgramCallForm(that, url) {
        if (isFormDirty) {
            bootboxConfirm(programSaveConfirmationText, 'Continue', 'Cancel', function(result) {
                if (result) {
                    that.renderModal(url)
                }
            })
        } else {
            that.renderModal(url)
        }
    }

    renderModal(url) {
        let that = this;
        getJsonRequest(url).then(response => {
            if (response.success) {
                showModal(response.html, "atalisModal", "atalisModal")
                that.initializeDatepickers()
                that.initializeAddFragment()
                that.enableOrDisableFields()
            }
        })
    }

    initializeDatepickers() {
        this.eoiDeadlinePresent = false;
        this.internalDeadlinePresent = false;

        $('.eoi_deadline-datepicker').each(function(index, el) {
            if ($(el).val()) {
                this.eoiDeadlinePresent = true;
                return false;
            }
        }.bind(this));

        $('.internal_deadline-datepicker').each(function(index, el) {
            if ($(el).val()) {
                this.internalDeadlinePresent = true;
                return false;

            }
        }.bind(this));

        let that = this;
        $("#opens_at-datepicker").datepicker(defaultGijgoConf);
        $("#deadline-datepicker").datepicker({
            format: global.gijgoDefaultFormat,
            uiLibrary: 'bootstrap4',
            weekStartDay: 1,
            locale: $('body').data('locale'),

            close: function() {
                that.deadlineChange()
            }
        });
        $("#phase_2_deadline-datepicker").datepicker(defaultGijgoConf);
        $("#resoultion_date-datepicker").datepicker(defaultGijgoConf);

        $(".eoi_deadline-datepicker").each(function(index, el) {
            $(el).datepicker({
                format: global.gijgoDefaultFormat,
                weekStartDay: 1,
                uiLibrary: "bootstrap4",
                locale: $('body').data('locale'),
                change: function(e) {
                    $(e.target.parentNode.parentNode).children(
                        ".eoi_deadline_enabled"
                    )[0].value = e.currentTarget.value;
                },
                open: function(e) {
                    let dataGuid = e.target.getAttribute('data-guid');
                    window.setInterval(function() {
                        that.markOfficialDeadline(dataGuid);
                    }, 100);
                }
            });
        });
        $(".internal_deadline-datepicker").each(function(index, el) {
            $(el).datepicker({
                format: global.gijgoDefaultFormat,
                weekStartDay: 1,
                uiLibrary: "bootstrap4",
                locale: $('body').data('locale'),
                change: function(e) {
                    $(e.target.parentNode.parentNode).children(
                        ".internal_deadline_enabled"
                    )[0].value = e.currentTarget.value;
                    let dataGuid = e.target.getAttribute('data-guid');
                    that.markOfficialDeadline(dataGuid);
                },
                open: function(e) {
                    let dataGuid = e.target.getAttribute('data-guid');
                    window.setInterval(function() {
                        that.markOfficialDeadline(dataGuid);
                    }, 100);
                }
            });
        });
    }

    markOfficialDeadline(dataGuid) {
        let officialDeadline = $('#deadline-datepicker').val();
        var currentDate = parseDate(officialDeadline, global.momentDefaultFormat);

        var day = currentDate._d.getDate();
        var month = currentDate._d.getMonth();
        var year = currentDate._d.getFullYear();
        if (document.querySelector('[guid="' + dataGuid + '"]').querySelector('[year="' + year + '"][month="' + month + '"][day="' + day + '"]')) {
            document.querySelector('[guid="' + dataGuid + '"]')
                    .querySelector('[year="' + year + '"][month="' + month + '"][day="' + day + '"]')
                    .classList
                    .add('deadline-date')
        }
    }

    initializeAddFragment() {
        $("a.add-calls-fragment").click(function() {
            var numTabs = $("#program-call-form div#tabs ul li").length + 1;

            $("#program-call-form div#tabs ul").append(
                "<li><a href='#fragment-" + numTabs + "'><span>" + numTabs + "</span></a></li>"
            );
            $("#program-call-form div#tabs").append("<div id='fragment-" + numTabs + "' class='tab-content calls-fragments'></div>");
            $("#program-call-form div#tabs").tabs("refresh");
        });
    }

    enableOrDisableFields() {
        if (this.hasPhase2DeadlineInputTarget) {
          let hasPhase2Deadline = (this.phase2DeadlineInputTarget.value.length != 0)
          enableOrDisable(this.phase2DeadlineInputTarget.parentNode, hasPhase2Deadline)
        }

        let universityId, universityConfig, internalDeadlineVisible, eoiDeadlineVisible, disabledValue, enabledValue, isNew, deadlineCondition;
        $(".internal_deadline-datepicker").each(function(index, el) {
            universityId = $(el).data('university-id')
            universityConfig = universitiesConfig[parseInt(universityId)]
            internalDeadlineVisible = universityConfig.internal_deadline_visible
            disabledValue = $(el).data('disabled-value')
            isNew = $('#is_new').val() == "true"
            if (isNew) {
                deadlineCondition = internalDeadlineVisible && disabledValue && disabledValue.length == 0
            } else {
                deadlineCondition = disabledValue && disabledValue.length == 0
            }
            enableOrDisable(el.parentNode, deadlineCondition)
        })

        $(".eoi_deadline-datepicker").each(function(index, el) {
            universityId = $(el).data('university-id')
            universityConfig = universitiesConfig[parseInt(universityId)]
            eoiDeadlineVisible = universityConfig.eoi_deadline_visible
            disabledValue = $(el).data('disabled-value')
            enabledValue = $(el).data('enabled-value')
            isNew = $('#is_new').val() == "true"
            if (isNew) {
               deadlineCondition = eoiDeadlineVisible && disabledValue && disabledValue.length == 0
            } else {
              deadlineCondition = (enabledValue != undefined || disabledValue != undefined) ||
                (
                  (enabledValue == undefined && disabledValue == undefined) && eoiDeadlineVisible
                )
            }
            enableOrDisable(el.parentNode, deadlineCondition)
        })
    }
}
